import https from '@/router/https'

//商店详情
export function getShop(param) {
	return https.fetchGet('/mallapp/shopinfo/myShopDetailsSpu', param)
}

//商品详情
export function getDetail(id, userId) {
  return https.fetchGet('/mallapp/goodsspu/getStoreSpuById/' + id + '/' + userId, );
}
