<template>
  <div>
    <!--导航-->
    <div>
      <van-nav-bar
        :title="shops.myShopDetails.name"
        :fixed="true"
        placeholder
      ></van-nav-bar>
    </div>
    <!--标签-->
    <div
      style="
        display: flex;
        justify-content: space-between;
        padding: 10px 24px 10px 16px;
        align-items: center;
        background-color: #ffffff;
      "
    >
      <div style="display: flex; align-items: center">
        <div>
          <van-image width="48" height="48" fit="contain" :src="shops.myShopDetails.imgUrl" />
        </div>
        <div style="margin-left: 5px">
          <div class="color333">{{ shops.myShopDetails.name }}</div>
          <!-- <div>
            <van-tag size="mini" color="#f1f1f1" text-color="#333" round>{{
              shops.detail
            }}</van-tag>
          </div> -->
        </div>
      </div>
    </div>
    <!-- 猜你喜欢 -->
    <div style="margin-top: 20px; padding: 10px 20px">
      <img
        style="width: 100%; height: 30px"
        src="../../assets/img/编组 6.png"
        alt
      />
    </div>
    <!-- <van-panel >
      <van-cell class="goods-express">
        <likeList/>
      </van-cell>
    </van-panel> -->
    <!--商品列表-->
    <div
      v-for="(bandas, index) in shops.myShopSpu.records"
      :key="index"
      @click="
        $router.push({
          path: '/shareGoods',
          query: { id: bandas.id, userId: id },
        })
      "
    >
      <div class="main-goodsList">
        <div class="left-goodsList">
          <div>
            <img style="margin: 2% 2% 0; width: 96%" :src="bandas.picUrls[0]" />
          </div>
          <div style="padding: 0 10px">
            <div style="height: 40px" class="van-multi-ellipsis--l2">
              <span class="word-goodsList">{{ bandas.name }}</span>
            </div>
          </div>

          <div style="padding: 10px">
            <span class="word4-goodsList">¥{{ bandas.sellPrice }}</span>
            <!-- <span class="info-goodsList" style="text-decoration: line-through"
              >¥{{ bandas.priceUp }}</span
            > -->
            <span class="info-goodsList" style="margin: 6px 0 0 7px"
              >已售{{ bandas.saleNum }}</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Grid, DropdownMenu } from "vant";
// import { getGoodDetail } from "@/api/proprietary/proprietary";
import { getShop } from "@/api/shareShop";

// import likeList from '@/views/mylike/index.vue'

export default {
  components: {
    [Grid.name]: Grid,
    [DropdownMenu.name]: DropdownMenu,
    // likeList
  },
  data() {
    return {
      id: "",
      shops: {},
      favoritesr: {},
      // messageHandlers:true,
      checkedGoods: ["1", "2", "3"],
      isFirst: null
    };
  },

  computed: {
    submitBarText() {
      const count = this.checkedGoods.length;
      return "结算" + (count ? `(${count})` : "");
    },

    totalPrice() {
      return this.goods.reduce(
        (total, item) =>
          total + (this.checkedGoods.indexOf(item.id) !== -1 ? item.price : 0),
        0
      );
    },
  },
  created() {},
  mounted() {
    if (this.$route.query.WoSession) {
      this.$store.commit("setSession", this.$route.query.WoSession);
    }
    if (this.$route.query.isFirst) {
      this.isFirst = this.$route.query.isFirst
    }
    this.id = this.$route.query.Id || this.$route.query.id;
    this.getObjs();
  },
  methods: {
    // 原生返回
    Close() {
      if (this.isFirst) {
        let u = navigator.userAgent;
        let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //androi
        let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
        if (isIOS) {
          window.webkit.messageHandlers.close.postMessage("up");
        }
        if (isAndroid) {
          window.android.close();
        }
      } else {
        this.$router.back(-1);
        // this.pageRefresh();
      }
    },
    // 获取数据
    getObjs() {
      getShop({userId: this.id}).then((res) => {
        // this.$toast(res.data.msg)
        if (res.data.code === 0) {
          this.shops = res.data.data;
          console.log(res);
          console.log(this.shops);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.left-goodsList,
.right-goodsList {
  float: left;
  margin-top: 10px;
  margin-left: 1%;
  margin-left: 1%;
  width: 48%;
  height: 61px;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
  height: 100%;
}
.right-goodsList {
  float: right;
}
.word-goodsList {
  left: 11px;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 14px;
  font-family: PingFangSC-Regular;
  line-height: 20px;
  text-align: left;
}
.info-goodsList {
  overflow-wrap: break-word;
  color: rgb(204, 197, 197);
  font-size: 10px;
  font-family: PingFangSC-Regular;
  line-height: 14px;
  text-align: left;
}
.word4-goodsList {
  overflow-wrap: break-word;
  color: rgba(168, 0, 0, 1);
  font-size: 18px;
  font-family: PingFangSC-Medium;
  line-height: 25px;
  text-align: left;
  margin-left: 1px;
}
.span1-goodsList {
  background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng4023c11a47ff50499a3b65314f2202aa49749ee6309e8822a346fb8a25334dff)
    100% no-repeat;
  width: 46px;
  height: 18px;
  /* 
	使用% 设置宽高以适应父元素的大小
	因为可能得高度坍塌，使用padding-top=（原图高/原图宽）设置高度
	*/
  background-size: cover;
  background-position: center;
  /*
	使用 background-size: cover; 自适应铺满
	background-position: center; 兼容不支持上面语句的浏览器版本
	*/
  display: inline-block;
}
.word10-goodsList {
  margin-left: 5px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 10px;
  font-family: PingFangSC-Regular;
  line-height: 14px;
  text-align: center;
}
</style>
